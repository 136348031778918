import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import dayjs from 'dayjs';

import Page from '../../components/Layout/Page/Page';
import OfferHeader from './Components/Header/OfferHeader';
import { RootState } from '../../store';
import { hideNotifications } from '../../notification_functions/notifications_functions';
import NoImageIcon from '../../assets/icons/No-image-icon.svg';
import classes from './OffersPreview.module.scss';
import { formatDate } from '../../utils/formatDate';
import { getLogoUrl } from '../../utils/company';
import FiltersNew from './Components/Filters/FiltersNew';
import { useAd } from '../../queries/ad';
import { OfferProduct, OfferProductStatus, OffersQuery, useOffers, useUpdateStatus } from '../../queries/offer';
import { PrintOffersModal } from '../../components/Printing/MyAdvertisementsPDF/PrintOffersModal';
import { OfferCompanyModal } from './Components/Modals/OfferCompanyModal';
import { ResolveOfferModal } from './Components/Modals/ResolveOfferModal';

const CustomActionCol = props => {
  const { row, setShowConfirmModal } = props;

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['my_adds_offers'];
  });

  if (row?.ad_auction_date) {
    if (dayjs(row?.ad_auction_date).isValid()) {
      if (dayjs(dayjs()).isAfter(row?.ad_auction_date)) {
        if (row?.status && row?.status === 4) {
          return (
            <div className={classes.accepted}>
              <span></span>
              {t.sent_to_auction}
            </div>
          );
        } else {
          return (
            <div className={classes.rejected}>
              <span></span>
              {t.declined}
            </div>
          );
        }
      } else if (dayjs(row?.ad_auction_date).isAfter(dayjs())) {
        if (row?.status && (row?.status === 3 || row?.status === 4)) {
          if (row?.status === 4) {
            return (
              <div className={classes.accepted}>
                <span></span>
                {t.sent_to_auction}
              </div>
            );
          } else {
            return (
              <div className={classes.rejected}>
                <span></span>
                {t.declined}
              </div>
            );
          }
        } else {
          return (
            <React.Fragment>
              <button
                className={classes.btn_reject}
                onClick={() =>
                  setShowConfirmModal({
                    show: true,
                    status: OfferProductStatus.DECLINED,
                    id: row.id,
                  })
                }
              >
                <span className={classes.icon}></span>
                {t.reject_offer}
              </button>
              <button
                className={classes.btn_accept}
                onClick={() =>
                  setShowConfirmModal({
                    show: true,
                    status: OfferProductStatus.AUCTION,
                    id: row.id,
                  })
                }
              >
                <span className={classes.icon}></span>
                {t.send_to_auction}
              </button>
            </React.Fragment>
          );
        }
      } else {
        return (
          <div className={classes.rejected}>
            <span></span>
            {t.declined}
          </div>
        );
      }
    } else {
      return <React.Fragment></React.Fragment>;
    }
  } else {
    if (row?.status && (row?.status === 2 || row?.status === 3)) {
      if (row?.status === 2) {
        return (
          <div className={classes.accepted}>
            <span></span>
            {t.accepted}
          </div>
        );
      } else {
        return (
          <div className={classes.rejected}>
            <span></span>
            {t.declined}
          </div>
        );
      }
    } else {
      return (
        <React.Fragment>
          <button
            className={classes.btn_reject}
            onClick={() =>
              setShowConfirmModal({
                show: true,
                status: OfferProductStatus.DECLINED,
                id: row.id,
              })
            }
          >
            <span className={classes.icon}></span>
            {t.reject_offer}
          </button>
          <button
            className={classes.btn_accept}
            onClick={() =>
              setShowConfirmModal({
                show: true,
                status: OfferProductStatus.ACCEPTED,
                id: row.id,
              })
            }
          >
            <span className={classes.icon}></span>
            {t.accept_offer}
          </button>
        </React.Fragment>
      );
    }
  }
};

function caret(order, column) {
  if (!order) {
    return (
      <span style={{ display: 'inline-block', position: 'relative', top: '-5px' }}>
        <span className={classes.sort_wrapper}>
          <span className={`${classes.caret} ${classes.caret_up}`}></span>
          <span className={classes.caret}></span>
        </span>
      </span>
    );
  } else if (order === 'asc') {
    return (
      <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
        <span className={classes.sort_wrapper}>
          <span className={`${classes.caret} ${classes.caret_up}`}></span>
        </span>
      </span>
    );
  } else if (order === 'desc') {
    return (
      <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
        <span className={classes.sort_wrapper}>
          <span className={classes.caret}></span>
        </span>
      </span>
    );
  } else {
    return null;
  }
}

const Back = () => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const navigate = useNavigate();

  return (
    <div className={classes.header_content_container}>
      <button type="button" className={classes['back_btn']} onClick={() => navigate(-1)}>
        &#60; {t.global['back']}
      </button>
    </div>
  );
};

export function OffersPreviewNew({ own }: { own?: boolean }) {
  const { id } = useParams();
  const [query, setQuery] = useState<OffersQuery>({
    pagination: { page: 1, size: 10 },
  });

  const [print, setPrint] = useState(false);

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const ad: any = useAd(id!);
  const offers = useOffers(id!, query);
  const update = useUpdateStatus();

  useEffect(() => () => hideNotifications(), []);

  const t_my_adds_offers = t.my_adds_offers;
  const t_global = t.global;

  const [showConfirmModal, setShowConfirmModal] = useState<
    { show: false } | { show: true; status: OfferProductStatus; id: string }
  >({ show: false });

  const [showConfirmModalCompany, setShowConfirmModalCompany] = useState<
    { show: false } | { show: true; data: OfferProduct }
  >({ show: false });

  const total = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {`${t_global['table_total_text']} ${from} ${t_global['table_total_text_1']} ${to} ${t_global['table_total_text_2']} ${size} ${t_global['table_total_text_3']}`}
    </span>
  );

  const onTableChange = (_type, { page, sizePerPage, sortField, sortOrder }) => {
    setQuery(prev => ({ ...prev, pagination: { page, size: sizePerPage }, sort: { sortBy: sortField, sortOrder } }));
  };

  const columns = useMemo(
    () => [
      {
        dataField: 'product.title',
        text: t.global.product,
        sort: true,
      },
      {
        dataField: 'product.quantity',
        text: t.auction_bidding.quantity,
        sort: true,
      },
      {
        dataField: 'offer_price',
        text: t_my_adds_offers.offered_price,
        sort: true,
      },
      {
        dataField: 'offer.company.naziv',
        text: t_my_adds_offers.company,
        formatter: (cell, row) => {
          return (
            <div
              onClick={() => {
                setShowConfirmModalCompany({ show: true, data: row });
              }}
            >
              {cell}
            </div>
          );
        },
        sort: true,
      },
      {
        dataField: 'created_at',
        text: t.offers.date,
        sort: true,
        formatter: value => formatDate(value),
      },
      {
        dataField: 'actions',
        text: '',
        hidden: ad && !dayjs().isAfter(dayjs(ad?.data?.auction_end_date)),
        formatter: (cell, row) =>
          !own ? <CustomActionCol row={row} setShowConfirmModal={setShowConfirmModal} /> : <></>,
      },
    ],
    [t]
  );

  const paginationOptions = {
    custom: true,
    totalSize: offers.data?.total ?? 0,
    page: query.pagination.page,
    sizePerPage: query.pagination.size,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: total,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
  };

  const action = showConfirmModal.show
    ? showConfirmModal.status === OfferProductStatus.DECLINED
      ? { button: t_my_adds_offers.reject_offer, body: t_my_adds_offers.reject_modal_body }
      : showConfirmModal.status === OfferProductStatus.AUCTION
      ? { button: t_my_adds_offers.send_to_auction, body: t_my_adds_offers.offer_send_to_auction }
      : { button: t_my_adds_offers.accept_offer, body: t_my_adds_offers.accept_modal_body }
    : { button: '', body: '' };

  return (
    <React.Fragment>
      <Page showBackBbtn={Back}>
        <OfferHeader
          Info={{
            date: ad?.data?.created_at!,
            number: ad?.data?.id!,
          }}
        />
        <div className={classes.product_wrapper}>
          <div className={classes.product_info}>
            <div className={classes.title_wrapper}>
              <div className={classes.image_wrapper}>
                <img
                  src={ad.data?.company?.logo ? getLogoUrl(ad.data.company) : ''}
                  alt="Company Logo"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = NoImageIcon;
                  }}
                />
              </div>
              <div className={classes.info_wrapper}>
                <div className={classes.title}>{ad.data?.title}</div>
                <div className={classes.category}>{ad.data?.industry?.name}</div>
              </div>
            </div>
          </div>
        </div>
        <FiltersNew onPrint={() => setPrint(true)} onChange={filter => setQuery(prev => ({ ...prev, filter }))} />
        <div className={classes.table_wrapper}>
          <div className={classes.table_container}>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <div>
                  <BootstrapTable
                    keyField="id"
                    remote={{ pagination: true }}
                    onTableChange={onTableChange}
                    data={offers.data?.data ?? []}
                    columns={columns}
                    classes={classes.offers_table}
                    noDataIndication={offers.isLoading ? t_global.loading : t_global.no_data}
                    sort={{ sortCaret: caret }}
                    {...paginationTableProps}
                  />
                  <div className={classes.pagination_wrapper}>
                    <PaginationTotalStandalone {...paginationProps} />
                    <div>
                      <PaginationListStandalone {...paginationProps} />
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              )}
            </PaginationProvider>
          </div>
        </div>
      </Page>
      {showConfirmModal.show && (
        <ResolveOfferModal
          show
          toggleModal={() => setShowConfirmModal({ show: false })}
          confirm={() => {
            update.mutate({ status: showConfirmModal.status, id: showConfirmModal.id });
            setShowConfirmModal({ show: false });
          }}
          title={action.button}
          body={action.body}
          confirmBtnClass={
            showConfirmModal.status === OfferProductStatus.DECLINED
              ? classes.refuse_btn_style
              : classes.accept_btn_style
          }
          confirmBtnIcon={<span className={classes.icon}></span>}
          confirmBtnText={action.button}
        />
      )}
      {showConfirmModalCompany.show && (
        <OfferCompanyModal
          show
          data={showConfirmModalCompany.data}
          ad={ad.data!}
          hideFooterBtns={
            showConfirmModalCompany.data.status === OfferProductStatus.ACCEPTED ||
            showConfirmModalCompany.data.status === OfferProductStatus.DECLINED ||
            showConfirmModalCompany.data.status === OfferProductStatus.AUCTION
              ? true
              : false
          }
          onHandleClose={() => setShowConfirmModalCompany({ show: false })}
          onHandleConfirm={status => {
            setShowConfirmModalCompany({ show: false });
            setShowConfirmModal({ show: true, status, id: showConfirmModalCompany.data.id.toString() });
          }}
          own={own}
        />
      )}
      {print && <PrintOffersModal data={offers.data?.data ?? []} onCancel={() => setPrint(false)} />}
    </React.Fragment>
  );
}
