import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Lightbox } from 'react-modal-image';

// import { IProductList } from "./model/product-list.model";
import { RootState } from '../../../store';

import classes from './ProductList.module.scss';
import { STATIC_URL } from '../../../config';

const ProductList = (props: any) => {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  const t_product = t.product;
  const t_global = t.global;
  const t_currencies = t.currencies;

  const [showMore, setShowmore] = useState<string[]>([]);
  const [previewImage, setPreviewImage] = useState({
    show: false,
    image: '',
  });

  return (
    <React.Fragment>
      <div className={classes.ProductItems}>
        {props.products.map((p, i) => {
          return (
            <div key={p.id} className={classes.product_wrapper}>
              <div className={classes.product_info_wrapper}>
                <div className={classes.header_wrapper}>
                  <div className={classes.title_wrapper}>
                    <div className={classes.title}>{p?.ime || ''}</div>
                    <div className={classes.category}>{p?.kategorija || ''}</div>
                  </div>
                </div>
                <div className={classes.description}>
                  {p?.opis ? (
                    p.opis.length > 255 && !showMore.includes(i.toString()) ? (
                      <>
                        {p.opis.substring(0, 255)}...
                        <span onClick={() => setShowmore([...showMore, i.toString()])}>{t_global.show_more}</span>
                      </>
                    ) : (
                      p.opis
                    )
                  ) : (
                    ''
                  )}
                </div>
                {p.images && p.images.length > 0 && (
                  <div className={classes.images_wrapper}>
                    {p?.images.map((image, index) => {
                      const src =
                        image[0].includes('data') || image[0].includes('https://') ? image : `${STATIC_URL}/${image}`;

                      return (
                        <div
                          key={index}
                          className={classes.image_wrapper}
                          onClick={() => {
                            if (image) {
                              setPreviewImage({
                                show: true,
                                image: src,
                              });
                            }
                          }}
                        >
                          <img src={src} alt="Product img" />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className={classes.sidebar_wrapper}>
                <div className={classes.quantity_wrapper}>
                  <div className={classes.title}>{t_product.quantity_requested}</div>
                  <div className={classes.quantity}>{p?.kolicina || ''}</div>
                </div>
                {/* <div className={classes.price_wrapper}>
                  <div className={classes.title}>{t_product.enter_price}</div>
                  <div className={classes.input_wrapper}>
                    <input type="number" name="price" disabled={props?.isAuctionFinished} />
                    <div className={classes.currency}>{t_currencies.MKD}</div>
                  </div>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
      {previewImage.show && (
        <Lightbox
          medium={previewImage.image}
          hideZoom={true}
          hideDownload={true}
          imageBackgroundColor="transparent"
          onClose={() =>
            setPreviewImage({
              show: false,
              image: '',
            })
          }
        />
      )}
    </React.Fragment>
  );
};

export default ProductList;
