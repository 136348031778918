import { Document, Page, Text, View, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import ModalComponent from '../../UI/ModalComponent/ModalComponent';
import { IPdfProps } from './model';
import { RootState } from '../../../store';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

const styles = StyleSheet.create({
  viewer: {
    width: '100%',
    height: '80vh',
  },
  page: {
    fontFamily: 'Roboto',
    paddingBottom: '18px',
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    borderTop: 'none',
    backgroundColor: '#FAFAFA',
    padding: '10px 5px',
  },
  header_column: {
    padding: '0 5px',
    borderRight: '1px solid #EBEBEB',
    fontSize: '13px',
    fontWeight: 'bold',
    height: '100%',
  },
  body: {
    padding: '10px',
    fontSize: '10px',
    fontWeight: 'normal',
    overflow: 'hidden',
  },
  row1: {
    minWidth: '90px',
    maxWidth: '90px',
    borderBottom: '2px solid #FAFAFA',
  },
  row2: {
    minWidth: '120px',
    maxWidth: '120px',
    borderBottom: '2px solid #FAFAFA',
  },
  row3: {
    minWidth: '90px',
    maxWidth: '90px',
    borderBottom: '2px solid #FAFAFA',
  },
  row4: {
    minWidth: '130px',
    maxWidth: '130px',
    borderBottom: '2px solid #FAFAFA',
  },
  row5: {
    minWidth: '75px',
    maxWidth: '75px',
    borderBottom: '2px solid #FAFAFA',
  },
  row6: {
    minWidth: '80px',
    maxWidth: '80px',
    borderBottom: '2px solid #FAFAFA',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 11,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#F9FAFC',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    padding: '0 10px',
  },
});

const MyOffersPdf = (props: IPdfProps) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const onCancelHandler = () => {
    props.onCancel();
  };

  const showStatus = status => {
    switch (status) {
      case '1':
        status = t.offers.in_progress;
        break;
      case '2':
        status = t.offers.auction;
        break;
      case '3':
        status = t.offers.accepted;
        break;
      case '4':
        status = t.offers.declined;
        break;
      case '5':
        status = t.offers.canceled;
        break;
      case '6':
        status = t.offers.finished;
        break;
      default:
        break;
    }
    return status;
  };

  const pdf = (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.table}>
            <View style={[styles.row, styles.header]} wrap={false}>
              <Text style={[styles.row1, styles.header_column]}>{t.offers.offer_number}</Text>
              <Text style={[styles.row2, styles.header_column]}>{t.offers.offer_name}</Text>
              <Text style={[styles.row3, styles.header_column]}>{t.offers.company}</Text>
              <Text style={[styles.row4, styles.header_column]}>{t.offers.categories}</Text>
              <Text style={[styles.row5, styles.header_column]}>{t.offers.offered_price}</Text>
              <Text style={[styles.row6, styles.header_column]}>{t.offers.status}</Text>
            </View>
            {props.data.map((offer, i) => {
              return (
                <View key={i} style={styles.row} wrap={true}>
                  <Text style={[styles.body, styles.row1]}>{offer?.ad_id}</Text>
                  <Text style={[styles.body, styles.row2]}>{offer?.title}</Text>
                  <Text style={[styles.body, styles.row3]}>{offer?.company}</Text>
                  <Text style={[styles.body, styles.row4]}>{offer?.category}</Text>
                  <Text style={[styles.body, styles.row5]}>{offer?.price_offer}</Text>
                  <Text style={[styles.body, styles.row6]}>
                    {offer?.status && offer?.status?.length > 0 && showStatus(offer?.status)}
                  </Text>
                </View>
              );
            })}
          </View>
          <View style={styles.pageNumber} fixed>
            <Text
              style={{
                color: '#9F9F9F',
              }}
            >
              {t.global.footer_rights}
            </Text>
            <Text render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );

  return (
    <>
      <ModalComponent
        show={true}
        title={t.global.document_overview}
        component={pdf}
        hideactions
        onCancel={onCancelHandler}
        onAccept={() => {}}
      />
    </>
  );
};

export default MyOffersPdf;
