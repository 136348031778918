import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';
import BasePhoneInput, { Props as BasePhoneInputProps, isValidPhoneNumber } from 'react-phone-number-input/input';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '../../../../notification_functions/notifications_functions';
import placeholder from '../../../../assets/imgPlaceholder.png';
import classes from './Step1.module.scss';
import * as _ from 'lodash';
import Button from '../../../../components/UI/Button/Button';
import ArrowRight from './../../../../assets/icons/ArrowRight.svg';
import Upload from './../../../../assets/icons/Upload.svg';
import Refresh from '../../../../assets/icons/Refresh.svg';
import Test from '../../../../assets/test.jpg';
import { generateCaptcha } from './generateCatcha';
import Checkbox from '../../../../components/UI/Checkbox/Checkbox';
import Switch from '../../../../components/UI/Switch/Switch';
import GlobalLoader from '../../../../components/UI/GlobalLoader/GlobalLoader';
import { client } from '../../../../services/http/instance';
import axios from 'axios';
import ModalComponent from '../../../../components/UI/ModalComponent/ModalComponent';
import Confirm from '../Confirm/Confirm';
import { CountrySelect } from '../../../../components/CountrySelect';
import { useIndustries } from '../../../../queries';

interface BaseInputProps {
  label: string;
  error?: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement>, BaseInputProps {}

const Input = forwardRef<HTMLInputElement, InputProps>(({ label, required, error, ...props }, ref) => {
  return (
    <div className={[classes.input_wrapper, error && classes.has_error].filter(Boolean).join(' ')}>
      <label>
        {label}
        {required && <span>*</span>}
      </label>
      <input ref={ref} {...props} />
      <div className={classes.error}>{error}</div>
    </div>
  );
});

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, BaseInputProps {}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ label, error, ...props }, ref) => {
  return (
    <div className={[classes.company_info_wrapper, error && classes.has_error].filter(Boolean).join(' ')}>
      <label>{label}</label>
      <textarea ref={ref} {...props} />
      <div className={classes.error}>{error}</div>
    </div>
  );
});

interface PhoneInputProps extends BasePhoneInputProps<InputHTMLAttributes<HTMLInputElement>>, BaseInputProps {}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(({ error, required, label, ...props }, ref) => {
  return (
    <div className={[classes.input_wrapper, error && classes.has_error].filter(Boolean).join(' ')}>
      <label>
        {label}
        {required && <span>*</span>}
      </label>
      <BasePhoneInput {...props} ref={ref} />
      <div className={classes.error}>{error}</div>
    </div>
  );
});

function UploadFile({
  id,
  src,
  onChange,
  accept,
  label,
}: {
  id: string;
  src?: string;
  onChange?(url: string, file?: File): void;
  accept?: string;
  label: string;
}) {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (!file || (accept && !accept?.includes(file.type))) {
      e.target.value = '';
      showNotification('Ве молиме прикачете PDF документ', 'danger');
      return;
    }

    const reader = new FileReader();

    reader.onload = e => {
      if (e.target?.result) {
        onChange?.(e.target.result.toString(), file);
      }
    };

    reader.readAsDataURL(file);
  }

  return (
    <div className={classes.image_container}>
      {accept
        ? src && <span className={classes.notificationLabel}>Документот е успешно прикачен</span>
        : src && (
            <div className={classes.image_wrapper}>
              <img src={src} alt="File" />
            </div>
          )}
      <div className={classes.upl_img_wrapper}>
        <div>
          <img src={Upload} alt={Upload} />
        </div>
        <label htmlFor={id} className={classes.text}>
          {label}
        </label>
        <input
          id={id}
          type="file"
          onChange={handleChange}
          accept={accept ?? 'image/png, image/jpeg'}
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
}

export function StepForms() {
  const navigate = useNavigate();
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);
  const { data } = useIndustries();
  const industries = data?.data ?? [];

  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');
  const [enteredCaptcha, setEnteredCaptcha] = useState('');
  const [catchaMessage, setCatchaMessage] = useState('');
  const [reportName, setReportName] = useState('');
  const [openRegisterModal, setOpenRegisterModal] = useState(false);

  let isReCaptchaValid = code === enteredCaptcha;

  useEffect(() => {
    generateCaptcha(handleCode);
  }, []);

  const handleCode = (code: string) => {
    setCode(code);
  };

  const updateCaptcha = event => {
    setEnteredCaptcha(state => event.target.value);
    if (event.target.value === code) {
      setCatchaMessage('Кодот е точен');
    } else {
      setCatchaMessage('Кодот не е точен');
    }

    setTimeout(() => setCatchaMessage(''), 5000);
  };

  const handleCancel = () => {
    navigate('/login');
  };

  const toggleRegisterModal = () => {
    setOpenRegisterModal(prev => !prev);
  };

  const handleRegister = async (data: any) => {
    try {
      await client.post('/auth/sign-up', { ...data, company: { ...data.company, reportName: reportName } });

      showNotification(t.global.success, 'success');

      reset();

      navigate('/login');
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        (error.response?.status === 409 ||
          (error.response?.status === 422 && error.response.data.errors?.['user.email'])?.find(error =>
            error.includes('already been taken')
          ))
      ) {
        return showNotification(t.components.email_already_in_use, 'danger');
      }

      showNotification(t.auth.error_occurred, 'danger');
    }
  };

  const companyValidationSchema = useMemo(
    () =>
      Yup.object({
        idNumber: Yup.string()
          .trim()
          .required(t.profile.input_required)
          .matches(/^\d+$/, t.profile.invalid_id_number)
          .length(7, t.profile.invalid_id_number),
        taxNumber: Yup.string()
          .trim()
          .required(t.profile.input_required)
          .test('len', t.profile.invalid_tax_number, val => [13, 15].includes(val.length)),
        name: Yup.string().trim().required(t.profile.input_required),
        address: Yup.string().trim().required(t.profile.input_required),
        city: Yup.string().trim().required(t.profile.input_required),
        municipality: Yup.string().trim().required(t.profile.input_required),
        postalCode: Yup.string().trim().required(t.profile.input_required).max(4, t.profile.invalid_postal_number),
        phoneNumber: Yup.string()
          .trim()
          .required(t.profile.input_required)
          .test('phone', t.profile.invalid_phone_number, value => isValidPhoneNumber(value)),
        email: Yup.string().trim().required(t.profile.input_required).email(t.profile.invalid_email),
        webLink: Yup.string().trim(),
        countryId: Yup.number().required(t.profile.input_required),
        info: Yup.string().trim().max(1000, t.profile.info_too_long),
        report: Yup.string().trim().required(t.profile.input_required),
        logo: Yup.string().trim().required(t.profile.input_required),
        // fax: Yup.string()
        //   .trim()
        //   .required(t.profile.input_required)
        //   .test('phone', t.profile.invalid_phone_number, value => isValidPhoneNumber(value)),
        industries: Yup.array().of(Yup.number()).min(1, 'Ве молиме одберете барем една индустрија'),
      }),
    [t]
  );

  const userValidationSchema = useMemo(
    () =>
      Yup.object({
        firstName: Yup.string().trim().required(t.profile.input_required),
        lastName: Yup.string().trim().required(t.profile.input_required),
        phoneNumber: Yup.string()
          .trim()
          .required(t.profile.input_required)
          .test('phone', t.profile.invalid_phone_number, value => isValidPhoneNumber(value)),
        email: Yup.string().trim().required(t.profile.input_required).email(t.profile.invalid_email),
        password: Yup.string().trim().required(t.profile.input_required).min(6, 'Мора да биде барем 8 карактери'),
        confirmPassword: Yup.string()
          .required()
          .oneOf([Yup.ref('password')], 'Потврди лозинка и лозинка мора да се исти'),
        newsletter: Yup.bool().required(t.profile.input_required),
      }),
    [t]
  );

  type SchemaType = {
    company: Yup.InferType<typeof companyValidationSchema>;
    user: Yup.InferType<typeof userValidationSchema>;
  };

  const schema =
    step === 1 ? Yup.object({ company: companyValidationSchema }) : Yup.object({ user: userValidationSchema });

  const {
    formState: { errors, isValid, isSubmitting },
    control,
    setValue,
    register,
    handleSubmit,
    reset,
    getValues,
  } = useForm<SchemaType>({
    resolver: yupResolver(schema as any),
    defaultValues: {
      company: {
        idNumber: '',
        taxNumber: '',
        name: '',
        address: '',
        city: '',
        municipality: '',
        postalCode: '',
        phoneNumber: '',
        // fax: '',
        webLink: '',
        info: '',
        industries: [],
        report: '',
        email: '',
        logo: '',
      },
      user: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
        newsletter: false,
      },
    },
  });

  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(async (values: any) => {
          try {
            if (step === 1) {
              setStep(2);
              return;
            }

            handleRegister(values);
          } catch {
            showNotification(t.profile.error_message, 'danger');
          }
        })}
      >
        <GlobalLoader show={isValid && isSubmitting} />
        {step === 1 ? (
          <>
            <div className={`${classes.StepSection1}`}>
              <h6 className={`${classes.StepTitle} pb-2 mb-4`}>Податоци за економски оператор</h6>
              <div className={classes.StepFormControls}>
                <div className={classes.FormGrid}>
                  <div className={classes.Section1}>
                    <Input
                      label={'Матичен број на економски оператор'}
                      required
                      {...register('company.idNumber')}
                      error={errors.company?.idNumber?.message}
                    />
                    <Input
                      label={'Единствен даночен број (ЕДБ)'}
                      required
                      {...register('company.taxNumber')}
                      error={errors.company?.taxNumber?.message}
                    />
                    <Input
                      label={'Назив на економски оператор'}
                      required
                      {...register('company.name')}
                      error={errors.company?.name?.message}
                    />
                    <Input
                      label={'Адреса'}
                      required
                      {...register('company.address')}
                      error={errors.company?.address?.message}
                    />
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <Input
                          label={'Град/Населено место'}
                          required
                          {...register('company.city')}
                          error={errors.company?.city?.message}
                        />
                      </div>

                      <div className="col-12 col-sm-6">
                        <Input
                          label={'Поштенски број'}
                          required
                          {...register('company.postalCode')}
                          error={errors.company?.postalCode?.message}
                        />
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <Input
                            label={'Општина'}
                            required
                            {...register('company.municipality')}
                            error={errors.company?.municipality?.message}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <TextArea
                            label={'Кратко инфо за компанијата'}
                            required
                            {...register('company.info')}
                            error={errors.company?.info?.message}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.Section2}>
                    <Controller
                      control={control}
                      name="company.phoneNumber"
                      render={({ field, fieldState }) => (
                        <PhoneInput
                          required
                          ref={field.ref}
                          value={field.value}
                          onChange={value => setValue('company.phoneNumber', value ?? '', { shouldValidate: true })}
                          label={t.profile.phone}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                    <Input
                      label={'Е-пошта'}
                      required
                      type="email"
                      {...register('company.email')}
                      error={errors.company?.email?.message}
                    />
                    <Input
                      label={'Веб локација'}
                      {...register('company.webLink')}
                      error={errors.company?.webLink?.message}
                    />
                    <Controller
                      control={control}
                      name="company.countryId"
                      render={({ field, fieldState }) => (
                        <CountrySelect
                          required
                          label={t.global.choose_country}
                          {...field}
                          error={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={classes.Section3}>
                    <div className={classes.File}>
                      <Controller
                        control={control}
                        name="company.report"
                        render={({ field, fieldState }) => (
                          <>
                            <UploadFile
                              label="Прикачи тековна состојба"
                              id="Company Report"
                              accept="application/pdf"
                              src={field.value ?? placeholder}
                              onChange={(url, file) => {
                                file?.name && setReportName(file?.name);
                                setValue('company.report', url, { shouldValidate: true });
                              }}
                            />
                            <div className={classes.error}>{fieldState.error?.message}</div>
                          </>
                        )}
                      />
                    </div>
                    <div className={classes.Logo}>
                      <Controller
                        control={control}
                        name="company.logo"
                        render={({ field, fieldState }) => (
                          <>
                            <UploadFile
                              label="Прикачи лого"
                              id="logo"
                              src={field.value ?? placeholder}
                              onChange={url => {
                                setValue('company.logo', url, { shouldValidate: true });
                              }}
                            />
                            <div className={classes.error}>{fieldState.error?.message}</div>
                          </>
                        )}
                      />
                    </div>
                    <div className={classes.ReCaptcha}>
                      <div className={classes.GeneratedCode}>
                        <div className={`${classes.Container} ${classes.Skew}`}>
                          <img src={Test} alt="" />
                          <div className={classes.Skew} id="captcha"></div>
                        </div>
                        <img
                          src={Refresh}
                          alt=""
                          onClick={() => {
                            generateCaptcha(handleCode);
                          }}
                        />
                      </div>
                      <div className={`${classes.Label} ${!isReCaptchaValid ? classes.Error : ''}`}>
                        Верификационен код <span>*</span>
                      </div>
                      <div className={classes.CodeInput}>
                        <input type="text" value={enteredCaptcha} onChange={updateCaptcha} />
                      </div>
                    </div>

                    <div style={{ color: code === enteredCaptcha ? 'green' : 'red' }}>{catchaMessage}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`pb-4 mt-3`}>
              <Controller
                control={control}
                name="company.industries"
                render={({ field, fieldState }) => (
                  <>
                    <h6 className="pb-2 mb-2">
                      Дејност на компанијата * <br />
                      {fieldState.error?.message && (
                        <small className={`${classes.CompanyIndustryError} form-text text-muted`}>
                          {fieldState.error?.message}
                        </small>
                      )}
                    </h6>
                    {industries.map(item => (
                      <div key={`industry-${item.id}`} className={classes.MarginSeparator}>
                        <Checkbox
                          label={item.name}
                          value={item.id}
                          checked={field.value?.includes(item.id)}
                          id={`industry-${item.id}`}
                          onValueChange={value => {
                            if (field.value?.includes(item.id)) {
                              setValue(
                                'company.industries',
                                field.value.filter(id => id !== item.id),
                                { shouldValidate: true }
                              );

                              return;
                            }

                            setValue('company.industries', [...(field.value ?? []), item.id], { shouldValidate: true });
                          }}
                        />
                      </div>
                    ))}
                  </>
                )}
              />
            </div>
            <div className={`${classes.Footer} row mt-3`}>
              <div className={`${classes.CancelButton} col-12 mt-2 mt-lg-0 col-lg-6 order-lg-2 text-lg-end`}>
                <Button label="Откажи" class="accent" asLink onClick={handleCancel} />
              </div>
              <div className="col-12 col-lg-2  order-lg-3 text-lg-end">
                <Button
                  label="Следен чекор"
                  icon={ArrowRight}
                  iconPosition={'right'}
                  class="accent"
                  hideLabelOnSmallScren
                  disabled={!isReCaptchaValid}
                  //   onClick={onNextStepHandler}
                  //   disabled={!isReCaptchaValid}
                  //   style={!isReCaptchaValid ? { backgroundColor: 'gray ' } : {}}
                  type="submit"
                />
                {!isReCaptchaValid && (
                  <div style={{ fontSize: '10px' }}>Ве молиме пополнете го полето "Верификационен код"</div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={`${classes.StepSection1} mb-4`}>
            <h6 className={`${classes.StepTitle} pb-2 mb-4`}>Регистрација на администратор</h6>
            <div className={classes.StepFormControls}>
              <div className={`${classes.HeightSetter} row`}>
                <div className="col-12 col-md-6">
                  <Input
                    label={'Име на администраторот'}
                    required
                    {...register('user.firstName')}
                    error={errors.user?.firstName?.message}
                  />
                  <Input
                    label={'Презиме на администраторот'}
                    required
                    {...register('user.lastName')}
                    error={errors.user?.lastName?.message}
                  />
                  <Controller
                    control={control}
                    name="user.phoneNumber"
                    render={({ field, fieldState }) => (
                      <PhoneInput
                        required
                        ref={field.ref}
                        value={field.value}
                        onChange={value => setValue('user.phoneNumber', value ?? '', { shouldValidate: true })}
                        label={'Број на телефон'}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Input
                    label={'Е-пошта'}
                    required
                    type="email"
                    {...register('user.email')}
                    error={errors.user?.email?.message}
                  />
                  <Input
                    label={'Лозинка'}
                    required
                    {...register('user.password')}
                    error={errors.user?.password?.message}
                    type="password"
                  />
                  <Input
                    label={'Потврди лозинка'}
                    required
                    {...register('user.confirmPassword')}
                    error={errors.user?.confirmPassword?.message}
                    type="password"
                  />
                </div>
                <hr />
                <div className="col-12">
                  <div className={classes.title}>{t.profile.info_title}</div>
                  <Controller
                    control={control}
                    name="user.newsletter"
                    render={({ field }) => (
                      <Switch
                        label={t.profile.info_label}
                        name="user.newsletter"
                        checked={field.value}
                        onChange={value => setValue('user.newsletter', value)}
                        switchPosition="start"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={`${classes.Footer} row mt-3`}>
              <div className={`${classes.CancelButton} col-12 mt-2 mt-lg-0 col-lg-6 order-lg-2 text-lg-end`}>
                <Button label="Откажи" class="accent" asLink onClick={handleCancel} />
              </div>
              <div className="col-12 col-lg-2  order-lg-3 text-lg-end">
                <Button
                  label="Следен чекор"
                  icon={ArrowRight}
                  iconPosition={'right'}
                  class="accent"
                  hideLabelOnSmallScren
                  onClick={toggleRegisterModal}
                  disabled={!isReCaptchaValid}
                />
                {!isReCaptchaValid && (
                  <div style={{ fontSize: '10px' }}>Ве молиме пополнете го полето "Верификационен код"</div>
                )}
              </div>
              <ModalComponent
                show={openRegisterModal}
                title="Потврда"
                footerinfo="(За активација на профилот проверете ја вашата електронска пошта.)"
                component={<Confirm />}
                type="submit"
                onCancel={toggleRegisterModal}
                onAccept={toggleRegisterModal}
              />
            </div>
          </div>
        )}
      </form>
    </>
  );
}
