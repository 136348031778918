export const generateCaptcha = (handleCode: (code: string) => void) => {
  const val = Math.floor(Math.random() * 4);

  //clear the contents of captcha div first
  document.getElementById('captcha')!.innerHTML = '';
  var charsArray = '01234567890';
  var lengthOtp = 4;
  var captcha: any = [];
  for (var i = 0; i < lengthOtp; i++) {
    //below code will not allow Repetition of Characters
    var index = Math.floor(Math.random() * charsArray.length); //get the next character from the array
    if (captcha.indexOf(charsArray[index]) === -1) captcha.push(charsArray[index]);
    else i--;
  }

  var canv = document.createElement('canvas');
  canv.id = 'captcha';
  canv.width = 70;
  canv.height = 50;
  var ctx = canv.getContext('2d');
  ctx!.font = '30px Arial';
  ctx!.fillStyle = '#b3b3b3';
  ctx!.fillText(captcha.join(''), 0, 35);

  document.getElementById('captcha')!.appendChild(canv); // adds the canvas to the body element

  handleCode(captcha.join(''));
};
