import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import ConfirmModal from '../Modals/ConfirmRemoveModal/ConfirmModal';
import classes from './AdvertisementForm.module.scss';
import AttachImagesModal from '../Modals/AttachImages/AttachImages';

const AdvertisementForm = props => {
  const index = props?.formIndex;
  const values = props?.values;
  const errors = props?.errors;

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showAttachImageModal, setShowAttachImageModal] = useState(false);

  const checkIfErrorExists = errors.adsFormValues && errors.adsFormValues.length > 0;

  const handleDeleteImage = (currentImage: any) => {
    props.update(index, {
      ...values,
      images: values.images.filter((image, id) => image.file_url !== currentImage.file_url),
    });
  };

  const addMoreAdvertisements = () => {
    props.append({
      title: '',
      industryId: '',
      quantity: 1,
      description: '',
      images: [],
    });
  };

  const deleteForm = (index: number) => {
    props.remove(index);
  };

  return (
    <React.Fragment>
      <form className={classes.ad_form}>
        <div
          className={`${classes.input_wrapper} ${
            checkIfErrorExists && errors?.adsFormValues[index]?.title ? classes.has_error : ''
          }`}
        >
          <label>{t.create_ad.product_title}</label>
          <input
            type="text"
            required
            {...props.register(`adsFormValues.${index}.title`)}
            onChange={e => {
              props.setValue(`adsFormValues.${index}.title`, e.target.value, { shouldValidate: true });
              props.update(index, { ...values, title: e.target.value });
            }}
          />
          <div className={classes.error}>{checkIfErrorExists && errors?.adsFormValues[index]?.title?.message}</div>
        </div>

        <div
          className={`${classes.input_wrapper} ${
            checkIfErrorExists && errors?.adsFormValues[index]?.description ? classes.has_error : ''
          }`}
        >
          <label>{t.create_ad.description_title}</label>
          <div className={classes.input_container}>
            <textarea
              required
              rows={4}
              {...props.register(`adsFormValues.${index}.description`)}
              onChange={e => {
                props.setValue(`adsFormValues.${index}.description`, e.target.value, { shouldValidate: true });
                props.update(index, { ...values, description: e.target.value });
              }}
            />
            <div className={classes.count_characters}>
              <span>{values?.description?.length || 0}</span> / 1000 {t.create_ad.characters}
            </div>
          </div>
          <div className={classes.error}>
            {checkIfErrorExists && errors?.adsFormValues[index]?.description?.message}
          </div>
        </div>
        <div className={classes.attach_images_wrapper}>
          <div className={classes.images_wrapper}>
            {values.images.map((value, i) => {
              return (
                <div className={classes.image_wrapper} key={i}>
                  <img
                    src={
                      props.advertisement &&
                      !value.uploaded &&
                      !value.file_url.includes('https://') &&
                      !value.file_url.includes('data')
                        ? `${process.env.REACT_APP_STATIC_URL}/${value.file_url}`
                        : value.file_url
                    }
                    alt=""
                  />
                  <div className={classes.icon_wrapper}>
                    <div className={classes.icon} onClick={() => handleDeleteImage(value)}></div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={classes.attach_btn_wrapper}>
            <label>{t.create_ad.attach_photos_desc}</label>
            <button type="button" onClick={() => setShowAttachImageModal(true)} className={classes.attach_button}>
              <div className={classes.icon}></div>
              {t.global.attach}
            </button>
          </div>
        </div>
        <div className={classes.error} style={{ color: 'red', fontSize: '12px' }}>
          {checkIfErrorExists && errors?.adsFormValues[index]?.images?.message}
        </div>
        <div className={classes.horizontal_line}></div>
        <div className={classes.action_btns_wrapper}>
          {props.forms > 1 && index !== 0 && (
            <button type="button" className={classes.remove} onClick={() => setShowConfirmModal(!showConfirmModal)}>
              {t.global.delete}
            </button>
          )}
          <button type="button" className={classes.add_more} onClick={addMoreAdvertisements}>
            <div className={classes.icon}></div>
            {t.create_ad.add_new_ad}
          </button>
        </div>
      </form>

      <ConfirmModal
        show={showConfirmModal}
        toggleModal={() => setShowConfirmModal(!showConfirmModal)}
        confirm={() => {
          setShowConfirmModal(!showConfirmModal);
          deleteForm(index);
        }}
      />

      {showAttachImageModal && (
        <AttachImagesModal
          show={showAttachImageModal}
          images={props?.values?.images}
          index={index}
          toggleModal={() => {
            setShowAttachImageModal(false);
          }}
          confirm={(formIndex, images) => {
            props.update(index, { ...values, images: images });
            props.setValue(`adsFormValues.${index}.images`, images, { shouldValidate: true });
            setShowAttachImageModal(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default AdvertisementForm;
