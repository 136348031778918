import dayjs from 'dayjs';
import Countdown from '../../UI/Countdown/Countdown';

export const TimeFormatter = (cell: string) => {
  const date = dayjs(cell);

  if (!date.isValid()) {
    return <div style={{ textAlign: 'center' }}>-</div>;
  }

  if (date.isSame(dayjs(), 'day')) {
    return (
      <Countdown
        date={dayjs(date).format('YYYY/MM/DD HH:mm:ss')}
        mode="light"
        returnTranslation={
          <div style={{ textAlign: 'center' }}>
            {dayjs(date).format('DD.MM.YYYY')} <br /> {dayjs(date).format('HH:mm')}
          </div>
        }
      />
    );
  }

  if (dayjs(date).isAfter(dayjs(), 'day')) {
    return (
      <div style={{ textAlign: 'center' }}>
        {dayjs(date).format('DD.MM.YYYY')} <br /> {dayjs(date).format('HH:mm')}
      </div>
    );
  }
};
