import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';

import Page from '../../components/Layout/Page/Page';
import { RootState } from '../../store';
import Countdown from '../../components/UI/Countdown/Countdown';
import { IConfirmModal } from '../OffersPreview/model';
import ConfirmModal from '../OffersPreview/Components/Modals/ConfirmModal/ConfirmModal';

import MoneyIcon from './../../assets/icons/Money.svg';
import ArrowTopRight from './../../assets/icons/ArrowTopRight.svg';
import CheckmarkCircleBlue from './../../assets/icons/checkmark_circle_blue.svg';
import { httpGet, httpPost } from '../../services/http';
import { hideNotifications, showNotification } from '../../notification_functions/notifications_functions';
import GlobalLoader from '../../components/UI/GlobalLoader/GlobalLoader';

import classes from './AuctionBidding.module.scss';
import dayjs from 'dayjs';
import { OfferProductStatus, useUpdateAuctionOfferStatus } from '../../queries/offer';

const CustomActionCol = props => {
  const { row, setShowConfirmModal, auctionDetails } = props;

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['my_adds_offers'];
  });

  if (row.hasOwnProperty('status') && (row.status === '2' || row.status === '3')) {
    if (row?.status === '2') {
      return (
        <div className={classes.accepted}>
          <span></span>
          {t.accepted}
        </div>
      );
    } else {
      return (
        <div className={classes.rejected}>
          <span></span>
          {t.declined}
        </div>
      );
    }
  } else {
    return (
      <React.Fragment>
        <button
          className={classes.btn_reject}
          onClick={() =>
            setShowConfirmModal({
              show: true,
              type: 'refuse',
              offer_id: row.id,
            })
          }
        >
          <span className={classes.icon}></span>
          {t.reject_offer}
        </button>
        <button
          className={classes.btn_accept}
          onClick={() =>
            setShowConfirmModal({
              show: true,
              type: 'accept',
              offer_id: row.id,
            })
          }
        >
          <span className={classes.icon}></span>
          {t.accept_offer}
        </button>
      </React.Fragment>
    );
  }
};

const AmmountFormatter = ({ ammount }) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });
  return <span>{`${ammount} ${t.currencies.MKD}`}</span>;
};

const AuctionBidding = () => {
  const { id } = useParams();
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const translations = t.auction_bidding;
  const t_currencies = t.currencies;
  const navigate = useNavigate();

  const [showConfirmModal, setShowConfirmModal] = useState<IConfirmModal>({
    show: false,
    type: '',
    offer_id: '',
  });

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [biddingFinished, setBiddingFinished] = useState<boolean>(false);
  const [bidValue, setBidValue] = useState<number | ''>();
  const [auctionDetails, setAuctionDetails]: any = useState({});
  const [auctionOffers, setAuctionOffers]: any = useState([]);
  const [lastOffer, setLastOffer]: any = useState({ offer_price: 0 });
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [offerOptionsFlag, setOfferOptionsFlag] = useState(1);
  const [auctionHasStarted, setAuctionHasStarted] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const fetchAdById = async () => {
    try {
      const response = await httpGet<any>(`/auction/get?tender_product_id=${id}&XDEBUG_SESSION_START=phpstorm`);
      setAuctionDetails(response?.data?.tender_details || {});
      setAuctionOffers(response?.data?.tender_offers || []);
      if (response?.data.last_offer?.offer_price) {
        setLastOffer({ offer_price: response.data.last_offer.offer_price });
      }
      if (dayjs().isAfter(dayjs(response?.data?.tender_details?.auction_end_date))) {
        if (offerOptionsFlag === 2) return;
        setOfferOptionsFlag(prev => prev + 1);
      }
      if (
        dayjs().isBefore(dayjs(response?.data?.tender_details?.auction_end_date)) &&
        dayjs().isAfter(dayjs(response?.data?.tender_details?.auction_start_date))
      ) {
        setAuctionHasStarted(true);
      }
      setNotFound(false);
    } catch (error: any) {
      if (error.response?.status === 404) {
        setNotFound(true);
      }
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchAdById();

    const interval = setInterval(() => {
      fetchAdById();
    }, 3000);

    return () => {
      clearInterval(interval);
      hideNotifications();
    };
  }, [id]);

  function onBidInputChange(event) {
    setBidValue(event.target.value);
  }

  const { mutate } = useUpdateAuctionOfferStatus();

  const confirmOfferFunc = () => {
    if (showConfirmModal.show) {
      if (showConfirmModal.type === 'refuse') {
        mutate(
          { id: showConfirmModal.offer_id.toString(), status: OfferProductStatus.DECLINED },
          { onSuccess: fetchAdById }
        );
      } else if (showConfirmModal.type === 'accept') {
        mutate(
          { id: showConfirmModal.offer_id.toString(), status: OfferProductStatus.ACCEPTED },
          { onSuccess: fetchAdById }
        );
      }
      setShowConfirmModal({
        show: false,
        type: '',
        offer_id: '',
      });
    }
  };

  const handleBidPost = async () => {
    let dataToSend = {
      offer_product_id: auctionDetails.id,
      bid_price: bidValue,
    };

    try {
      const response = await httpPost<any, any>(`auction/add-offer?XDEBUG_SESSION_START=phpstorm`, dataToSend);
      // TODO: Handle status or message to show notification to user
      // 1. onSidebarClose() -> close modal on success and show success notification
      // 2. Show error notification on error
    } catch (error) {
      // Show error notification on error
      console.error(error);
    }

    if (!showMessage) {
      setShowMessage(true);
      setTimeout(() => {
        setBidValue('');
        setShowMessage(false);
      }, 1000);
    }
  };

  const BackBtn = () => {
    return (
      <div className={classes.header_content_container}>
        <button type="button" className={classes['back_btn']} onClick={() => navigate(-1)}>
          &#60; {translations['back']}
        </button>
        {dayjs(auctionDetails.auction_end_date).isValid() && (
          <div className={classes.coundown_container}>
            <div className={classes.icon_wrapper}>
              <img src={CheckmarkCircleBlue} alt="icon" />
            </div>
            <div className={classes.coundown_wrapper}>
              <div className={classes.label}>{translations.auction_end}</div>

              {dayjs(auctionDetails.auction_end_date).isSame(dayjs(), 'day') ? (
                <div className={classes.countdown}>
                  <Countdown
                    date={auctionDetails.auction_end_date}
                    onFinish={() => setBiddingFinished(true)}
                    returnTranslation={translations.finished}
                    mode="auction"
                  />
                </div>
              ) : (
                <div style={{ textAlign: 'left' }}>
                  {dayjs(auctionDetails.auction_end_date).format('DD-MM-YYYY')} <br />{' '}
                  {dayjs(auctionDetails.auction_end_date).format('HH:mm')}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: 'bidder',
      text: translations['bidder'],
    },
    {
      dataField: 'amount_difference',
      text: translations['amount_difference'],
    },
    {
      dataField: 'amount',
      text: translations['amount'],
      formatter: (cell, row) => <AmmountFormatter ammount={cell} />,
    },
    {
      dataField: 'auction',
      text: translations['actions'],
      hidden: auctionDetails.own_auction ? !dayjs().isAfter(dayjs(auctionDetails.auction_end_date)) : true,
      formatter: (cell, row) => {
        return <CustomActionCol row={row} setShowConfirmModal={setShowConfirmModal} auctionDetails={auctionDetails} />;
      },
    },
  ];

  const tableData = auctionOffers.map((offer, i) => {
    const previousOffer = auctionOffers[i + 1] ? auctionOffers[i + 1].offer_price : null;
    const difference = previousOffer ? offer.offer_price - previousOffer : 0;
    return {
      id: offer.id,
      bidder: offer.company,
      amount_difference: `${difference && difference > 0 ? '+' : ''} ${difference}`,
      amount: offer.offer_price,
      status: offer.status,
    };
  });

  return (
    <React.Fragment>
      <Page showBackBtn={BackBtn}>
        <div className={classes.auction_bidding_screen_wrapper}>
          {notFound ? (
            <div className={classes.header_wrapper}>{t.auction_bidding.no_offer_on_ad}</div>
          ) : (
            <>
              <div className={classes.header_wrapper}>
                <div className={classes.page_title}>{translations['auction']}</div>
                <div className={classes.auction_number}>
                  {translations['auction_number']}: <span>{auctionDetails.id}</span>
                </div>
              </div>
              <div
                className={classes.auction_info_wrapper}
                style={{
                  gridTemplateColumns: auctionDetails.own_auction ? 'auto 0px' : 'auto 380px',
                }}
              >
                <div className={classes.auction_info}>
                  {[1].map(n => {
                    return (
                      <div key={n} className={classes.details}>
                        <div className={classes.title_wrapper}>
                          <div className={classes.image_wrapper}>
                            <img
                              src={`${process.env.REACT_APP_STATIC_URL}/companies/${auctionDetails.company_id}/logos/${auctionDetails.image_url}`}
                              alt=""
                            />
                          </div>
                          <div className={classes.title_container}>
                            <div className={classes.title}>{auctionDetails.title}</div>
                            <div className={classes.category}>{auctionDetails.category}</div>
                          </div>
                        </div>
                        <div className={classes.description}>{auctionDetails.description}</div>
                        <div className={classes.company_quantity}>
                          <div className={classes.company_wrapper}>
                            <div className={classes.label}>{translations['company']}</div>
                            <div className={classes.name}>
                              <Link to={`/company/${auctionDetails.company_id}`} className={classes.company_link}>
                                {auctionDetails.company}
                              </Link>
                            </div>
                          </div>
                          <div className={classes.quantity_wrapper}>
                            <div className={classes.label}>{translations['quantity']}</div>
                            <div className={classes.quantity}>{auctionDetails.quantity}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {auctionDetails.own_auction === false && (
                  <div className={classes.bidding_wrapper}>
                    <div className={classes.bidding_container}>
                      <div className={classes.last_offer_label}>{translations['last_bid']}</div>
                      <div className={classes.last_offer}>
                        <img src={MoneyIcon} alt="" />
                        <span>
                          {lastOffer.offer_price} {t_currencies.MKD}
                        </span>
                      </div>
                      <div className={classes.new_offer_label}>
                        {translations['new_ammount']} ({translations['minimum']} 100 {t_currencies.MKD}*)
                      </div>
                      <div className={classes.offer_input_wrapper}>
                        <div className={classes.icon_wrapper}>
                          <img src={ArrowTopRight} alt="Arrow icon" />
                        </div>
                        <input
                          type="number"
                          className={classes.offer_input}
                          value={bidValue}
                          onChange={onBidInputChange}
                          disabled={showMessage || biddingFinished || !auctionHasStarted}
                        />
                        <div className={classes.currency}>{t_currencies.MKD}</div>
                      </div>
                      <button
                        className={`${classes.send_offer} ${showMessage ? classes.success : ''}`}
                        onClick={handleBidPost}
                        disabled={
                          showMessage ||
                          biddingFinished ||
                          (bidValue && bidValue > 99 ? false : true) ||
                          !auctionHasStarted
                        }
                      >
                        {showMessage ? translations['highest_bid'] : translations['send_offer']}
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className={auctionDetails.own_auction ? classes.table_wrapper : classes.rang_list_wrapper}>
                <div className={classes.rang_list_title}>{translations['auction_rang_list']}</div>
                <div className={auctionDetails.own_auction ? classes.table_container : classes.rang_list_table}>
                  <BootstrapTable
                    keyField="id"
                    data={tableData}
                    columns={tableColumns}
                    classes={auctionDetails.own_auction ? classes.offers_table : classes.bidding_table}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Page>
      <ConfirmModal
        show={showConfirmModal.show}
        toggleModal={() =>
          setShowConfirmModal({
            show: false,
            type: '',
            offer_id: '',
          })
        }
        confirm={confirmOfferFunc}
        title={showConfirmModal.type === 'refuse' ? translations.auction_reject_offer : translations.accept_offer}
        body={showConfirmModal.type === 'refuse' ? translations.reject_modal_body : translations.accept_modal_body}
        confirmBtnClass={showConfirmModal.type === 'refuse' ? classes.refuse_btn_style : classes.accept_btn_style}
        confirmBtnIcon={<span className={classes.icon}></span>}
        confirmBtnText={showConfirmModal.type === 'refuse' ? translations.reject_offer : translations.accept_offer}
      />
      <GlobalLoader show={showLoader} />
    </React.Fragment>
  );
};

export default AuctionBidding;
