import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Form } from 'react-bootstrap';

import { IFilters, ISearchParameters } from './filters.model';
import { RootState } from '../../../../../store';
import { httpGet } from '../../../../../services/http';
import ActiveAdvertisementsPDF from '../../../../../components/Printing/ActiveAdvertisementsPDF/ActiveAdvertisementsPDF';

import Search from './../../../../../assets/icons/Search.svg';
import Download from './../../../../../assets/icons/Download.svg';
import Filter from './../../../../../assets/icons/Filter.svg';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './Filters.module.scss';

const Filters = (props: IFilters) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const t_current_ads = t.current_advertisements;

  const [viewPdf, setViewPdf] = useState<boolean>(false);
  const [categories, setCategories] = useState<[{ id: string; name: string }] | []>([]);
  const [searchParameters, setSearchparameters] = useState<ISearchParameters>({
    searchValue: '',
    category_id: '',
  });

  useEffect(() => {
    const triggerTimeout = setTimeout(() => {
      props.onSearchChange(searchParameters);
    }, 500);

    return () => clearTimeout(triggerTimeout);
  }, [searchParameters]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await httpGet<any>('/industry/get?XDEBUG_SESSION_START=phpstorm');
      if (response?.data?.data) {
        setCategories(response.data.data);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchparameters(state => {
      return {
        ...state,
        searchValue: event.target.value,
      };
    });
  };

  const onCategoryChange = (value: string | null) => {
    setSearchparameters(state => {
      return {
        ...state,
        category_id: value,
      };
    });
  };

  const previewPdfHandler = () => {
    setViewPdf(true);
  };

  const onCancelHandler = () => {
    setViewPdf(false);
  };

  const CustomCategoryMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
      const [value, setValue] = useState('');
      const categories = React.Children.toArray(children).filter(
        (child: any) => child.props.children !== t_current_ads.no_categories
      ).length;
      return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
          <Form.Control
            autoFocus
            className={`mx-3 my-2 w-aut ${classes.filter_input}`}
            placeholder={t_current_ads.search_categies}
            onChange={e => setValue(e.target.value)}
            value={value}
            disabled={categories === 0}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child: any) => !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <React.Fragment>
      <div className={`${classes.header_container}`}>
        <div className={`${classes.title} text-center`}>{t_current_ads.active}</div>

        <div className={`${classes.search_bar}`}>
          <img src={Search} alt="search" className={classes.icon} />
          <input type="text" placeholder={t_current_ads.search} onChange={onChangeHandler} />
        </div>

        <div className={classes.actions}>
          {/* <button
                        className={classes.download_list}
                        onClick={previewPdfHandler}
                    >
                        <img src={Download} alt="download" />
                        <span className="">{t_current_ads.download_list}</span>
                    </button> */}
          <Dropdown
            className={classes.dropdown_filter_wrapper}
            onSelect={(eventKey, event) => onCategoryChange(eventKey)}
          >
            <Dropdown.Toggle className={classes.button} id="categories_filter">
              <img src={Filter} alt="Filter" />
              {/* {props.categoryId ? categories[props.categoryId].name :} */}
              {t_current_ads.category}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomCategoryMenu} align="end" className={classes.filter_menu}>
              {categories.length > 0 ? (
                categories.map((category, index) => {
                  return (
                    <Dropdown.Item eventKey={category.id} key={index}>
                      {category.name}
                    </Dropdown.Item>
                  );
                })
              ) : (
                <div style={{ textAlign: 'center' }}>{t_current_ads.no_categories}</div>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {viewPdf && <ActiveAdvertisementsPDF data={props.advertisements} onCancel={onCancelHandler} />}
    </React.Fragment>
  );
};

export default Filters;
