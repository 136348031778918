import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../store';

import classes from './ConfirmModal/ConfirmModal.module.scss';

interface Props {
  show: boolean;
  title?: string;
  body?: string;
  confirmBtnClass?: string;
  confirmBtnIcon?: JSX.Element;
  confirmBtnText?: string;
  toggleModal(): void;
  confirm(): void;
}

export function ResolveOfferModal({
  show,
  title,
  body,
  confirmBtnClass,
  confirmBtnIcon,
  confirmBtnText,
  toggleModal,
  confirm,
}: Props) {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  return (
    <React.Fragment>
      <Modal show={show} className={classes.modal_wrapper} centered>
        <Modal.Header className={classes.custom_header}>
          <Modal.Title>{title || t.create_ad.remove_ad_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.custom_body}>{body || t.create_ad.remove_confirm_text}</Modal.Body>
        <Modal.Footer className={classes.custom_footer}>
          <button className={classes.cancel_btn} onClick={toggleModal}>
            {t.global.cancel}
          </button>
          <button className={`${classes.confirm_btn} ${confirmBtnClass}`} onClick={confirm}>
            {confirmBtnIcon} {confirmBtnText || t.global.delete}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
