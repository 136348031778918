import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../../../store';
import { RoutesEnum } from '../../../../enums/routes';

import MessageIcon from '../../../../assets/icons/Message.svg';
import NoImageIcon from '../../../../assets/icons/No-image-icon.svg';

import classes from './ConfirmModalCompany/ConfirmModalCompany.module.scss';
import { download } from '../../../../utils/download';
import { OfferProduct, OfferProductStatus } from '../../../../queries/offer';
import { Ad } from '../../../../queries/ad';
import { getLogoUrl } from '../../../../utils/company';

interface Props {
  show: boolean;
  confirmBtnText?: string | null;
  hideFooterBtns?: boolean;
  data: OfferProduct;
  ad: Ad;
  onHandleClose: () => void;
  onHandleConfirm: (status: OfferProductStatus) => void;
  own?: boolean;
}

export function OfferCompanyModal({
  show,
  data,
  ad,
  hideFooterBtns,
  confirmBtnText,
  onHandleClose,
  onHandleConfirm,
  own,
}: Props) {
  const navigate = useNavigate();

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  return (
    <React.Fragment>
      <Modal show={show} className={classes.modal_wrapper} centered size="lg" onHide={onHandleClose}>
        <Modal.Header className={classes.custom_header} closeButton>
          <Modal.Title>{t.my_adds_offers.company}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.custom_body}>
          <div className={classes.company_info_wrapper}>
            <div className={classes.image_wrapper}>
              <div className={classes.image_container}>
                <img
                  src={getLogoUrl(data.offer.company)}
                  alt="company logo"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = NoImageIcon;
                  }}
                />
              </div>
              <div className={classes.company_name}>{data.offer.company.naziv}</div>
            </div>
            <div className={classes.company_info}>
              <div className={classes.company_name}>{data.offer.company.naziv}</div>
              <div className={classes.website}>{data.offer.company.web_location}</div>
              <div className={classes.email}>{data.offer.company.email}</div>
              <div className={classes.address}>{data.offer.company.address}</div>
              <div className={classes.phone}>{data.offer.company.phone_number}</div>
            </div>
            <div className={classes.message_icon}>
              <div className={classes.image_wrapper} onClick={() => navigate(RoutesEnum.Messages)}>
                <img src={MessageIcon} alt="message icon" />
              </div>
            </div>
          </div>
          <div className={classes.contact_wrapper}>
            <div className={classes.title}>{t.my_adds_offers.contact_person}</div>
            <div className={classes.contact_info_wrapper}>
              <div className={classes.name}>{ad.contact_name}</div>
              <div className={classes.email}>{ad.contact_email}</div>
              <div className={classes.phone}>{ad.contact_phone}</div>
            </div>
          </div>
          <div className={classes.comment_wrapper}>
            <div className={classes.title}>{t.my_adds_offers.comment}</div>
            <textarea
              name="comment"
              placeholder={t.my_adds_offers.comment}
              rows={4}
              value={data.offer.comment ?? ''}
              disabled={true}
            ></textarea>
          </div>
          {data.offer.documents.length > 0 && (
            <div className={classes.additional_docs_wrapper}>
              <div className={classes.title}>{t.my_adds_offers.additional_docs}</div>
              <div className={classes.docs_wrapper}>
                {data.offer.documents.map((doc, i) => {
                  return (
                    <div
                      className={classes.doc}
                      onClick={() => {
                        download(`/offers/${doc.offer_id}/files/${doc.id}`);
                      }}
                    >
                      <div className={classes.icon}></div>
                      {doc.file_name}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={classes.custom_footer}>
          <div className={classes.price_wrapper}>
            <div className={classes.label}>{t.my_adds_offers.offered_price}:</div>
            <div className={classes.price}>{data.offer_price}</div>
          </div>
          {!hideFooterBtns && !own && (
            <div className={classes.buttons_wrapper}>
              <button className={classes.refuse_btn} onClick={() => onHandleConfirm(OfferProductStatus.DECLINED)}>
                <span className={classes.icon}></span>
                {t.my_adds_offers.reject_offer}
              </button>
              <button className={classes.accept_btn} onClick={() => onHandleConfirm(OfferProductStatus.ACCEPTED)}>
                <span className={classes.icon}></span>
                {confirmBtnText || t.my_adds_offers.accept_offer}
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
